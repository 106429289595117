<template>
    <main>
        <div id="accessRenew" v-show="goodLink != 0">
            <form v-if="goodLink == 1" @submit.prevent="validate();" class="text-center">
                <img src="@/assets/images/logo-ecodev.png">
                <!--  -->
                <label type="text" id="email" disabled required class="btn mb-2 text-center">
                    {{ email }}
                </label>

                <h5>Veuillez entrez le nouveau mot de passe.</h5>
                <div class="input-group mb-2 float-start">
                    <span class="input-group-text" id="basic-addon1"><i class="bi bi-lock-fill"></i></span>

                    <input type="password" class="form-control" id="password" required v-model="password"
                        placeholder="Nouveau mot de passe">

                    <span class="input-group-text" @click="password_toggle()">
                        <i class="bi bi-eye-fill" id="show_eye"></i>
                        <i class="bi bi-eye-slash-fill d-none" id="hide_eye"></i>
                    </span>
                </div>
                <div class="float-start text-start mb-3">
                    <a class="importantSentence">
                        * Recommandation: Votre mot de passe doit avoir au moins une longueur de 5 caractères
                        et doit contenir des symboles, des majuscules, des minuscules et des chiffres.*
                    </a>
                </div>
                <br>
                <div class="w-100 text-center">
                    <button type="submit">
                        Enregistrer
                        <i class="bi bi-check-lg"></i>
                    </button>
                </div>
            </form>

            <div v-else-if="goodLink == -1">
                <img src="@/assets/images/logo-ecodev.png">
                <h4>Ce lien a expiré.</h4>
            </div>
        </div>
    </main>
</template>

<script>
import axios from "axios";
const $ = require("jquery");
//
import waitLoader from "/src/common/js/waitLoader";
import popup from "/src/common/js/popup";
import Globals from "/src/common/js/Globals";
import functions from "@/common/js/functions";



export default {
    data() {
        return {
            email: "",
            goodLink: 0,
            password: ''
        };
    },

    mounted() {
        waitLoader.show();
        //
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.email = urlParams.get('email');
        this.code = urlParams.get('tc');
        //
        this.update();
        //
        waitLoader.hide();
    },

    methods: {
        update() {
            const checkCode = async () => {
                let fd = new FormData();
                fd.append('query', "check-domain-email-link/" + this.email + "/" + this.code);
                try {
                    const resp = await axios.post(Globals.website_url + "php/api.php", fd);
                    let h = resp.data;
                    //
                    if (h != "1") {
                        this.goodLink = -1;
                        return;
                    }
                    //
                    this.goodLink = 1;
                    //
                } catch (err) {
                    // Handle Error Here
                    console.error(err);
                    //
                    this.goodLink = -1;
                }
            };
            checkCode();
        },

        password_toggle() {
            var x = document.getElementById("password");
            var show_eye = document.getElementById("show_eye");
            var hide_eye = document.getElementById("hide_eye");
            hide_eye.classList.remove("d-none");
            if (x.type === "password") {
                x.type = "text";
                show_eye.style.display = "none";
                hide_eye.style.display = "block";
            } else {
                x.type = "password";
                show_eye.style.display = "block";
                hide_eye.style.display = "none";
            }
        },

        validate() {
            let email = this.email;
            let domainName = email.substring(email.indexOf('@', 0) + 1, email.length);
            let password = this.password;
            let yourEmail = "null";
            //
            waitLoader.show();
            //
            //
            $('#popupYes').click(function () {
                if (popup.action != "ok") {
                    return;
                }
                location = "https://" + domainName + ":2096";
            });


            let data = {
                "action": "email-set-password",
                "domain": domainName,
                "email": email,
                "password": password,
                "yourEmail": yourEmail
            }
            axios.post(Globals.website_url + "php/emailApi/api.php", data).then((response) => {
                let h = response.data;
                //on error
                if (h <= 0) {
                    //
                    waitLoader.hide();
                    //say error
                    popup.action = "notification";
                    popup.setTitle("Notification");
                    popup.setMessage(
                        "Impossible de reinitialiser le mot de passe. Veuillez reéssayer ultérieurement"
                    );
                    popup.setButton("Ok");
                    popup.show();
                    return;
                }
                if (h.status == 0) {
                    waitLoader.hide();
                    //say error
                    popup.action = "notification";
                    popup.setTitle("Notification");
                    popup.setMessage(
                        "Votre mot de passe n'est pas assez sécurisé.<br><a style='color:#800 !important'>Le mot de passe doit avoir au moins une longueur de 8 caractère et doit contenir des symboles, des majuscules, des minuscules et des chiffres.</a>"
                    );
                    popup.setButton("Ok");
                    popup.show();
                    return;
                }
                //
                waitLoader.hide();
                //
                popup.action = "ok";
                popup.setTitle("Notification");
                popup.setMessage(
                    "Mot de passe modifié avec succès."
                );
                popup.setButton("Ok");
                popup.show();
                //
            }).catch(() => {
                popup.action = "notification";
                popup.setTitle("Notification");
                popup.setMessage(
                    "Veuillez vérifier votre connexion à internet."
                );
                popup.setButton("Ok");
                popup.show();
                //
                waitLoader.hide();
            });
        }
        //
    }
}
</script>

<style scoped>
main {
    width: 100%;
    height: 100vh;
    background: url("/src/assets/images/app-bg.jpg") no-repeat center center fixed;
    background-size: cover;
}

#accessRenew {
    color: black;
    max-width: 680px;
    width: 100%;
    background-color: #F2FBFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    float: left;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
    padding: 112px;
}

#accessRenew img {
    width: 260px;
    margin-bottom: 32px;
}

#accessRenew h3 {
    margin-bottom: 24px;
}

form input {
    border: solid 1px #ddd;
    padding: 12px 12px;
}

form button[type=submit] {
    margin-left: 12px;
    margin-top: 32px;
    border: none;
    border-radius: 3px;
    padding: 8px 0;
    width: 240px;
    box-sizing: content-box;
    color: white;
    background-color: #111a2b;
    font-size: 17px;
}

form button[type=submit]:hover {
    color: white;
    background-color: #1b2842;
}

form button[type=submit] i {
    color: rgba(123, 255, 0);
    font-size: 16px;
}

form button[type=submit]:hover {
    background-color: #346;
}

#email {
    background-color: #e9ecef;
    border: solid 1px #ced4da;
    width: 100%;
    font-size: 16px;
}

/**/
@media only screen and (max-width: 480px) {
    #accessRenew {
        box-shadow: none;
        border: solid 1px #ccc;
        border-left: none;
        border-right: none;
        border-radius: 0;
    }
}

@media only screen and (max-height: 512px) {
    #accessRenew {
        top: 0;
        transform: translateY(0) translateX(-50%);
    }
}

.importantSentence {
    font-size: 13px;
    color: #5b5b5b !important;
}
</style>