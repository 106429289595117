<template>
  <div id="allServices">
    <div id="allServicesFilter">
      <a>Filtres des services</a>
      <select id="allServicesFilterSelect" @change="filter()">
        <option value="0" selected="selected">Tous les services</option>
        <option value="1">Services actifs</option>
        <option value="2">Services initié</option>
        <option value="3">Services expirés</option>
        <option value="4">Services en attente de paiement</option>
        <option v-for="(offerType, index) in offerTypes" v-bind:key="index">
          {{ offerType.type }}
        </option>
      </select>

      <button
        id="subsUpdateBtn"
        type="button"
        class="form-control btn-success border-success w-auto"
        @click="forceUpdate()"
        enable="true"
      >
        Actualiser<i class="bi bi-arrow-clockwise text-white"></i>
      </button>
    </div>

    <div
      v-if="subscriptions.length == 0"
      empty
      class="manageServicesItem row w-100 d-flex justify-content-center"
    >
      <h5>Vous n'avez aucun service à votre actif</h5>
      <router-link
        to="/client/offers"
        class="w-100 d-flex justify-content-center pt-2"
      >
        <button id="manageServicesEmpty">Commander un Service</button>
      </router-link>
    </div>

    <div
      class="manageServicesItem row w-100 p-2"
      v-for="(subscription, index) in subscriptions"
      v-bind:key="index"
      v-show="
        subscription.exp_date != '1970-01-01' &&
        subscription.starting_date != '1970-01-01' &&
        subscription.payment_date != '1970-01-01'
      "
    >
      <div class="manageServicesItemPart1 col-xl-6 col-12">
        <div
          class="row manageServicesSubitem"
          v-if="
            subscription.domain_name != null && subscription.domain_name != ''
          "
        >
          <b class="col-sm-4 col-12">Domaine</b>
          <a class="col-sm-8 col-12">{{ subscription.domain_name }}</a>
        </div>

        <div class="row manageServicesSubitem">
          <b class="col-sm-4 col-12">Service</b>
          <a class="col-sm-8 col-12">{{
            subscription.subscription_type +
            " " +
            subscription.subscription_label
          }}</a>
        </div>

        <div class="row manageServicesSubitem">
          <b class="col-sm-4 col-12">Date. début</b>
          <a class="col-sm-8 col-12">{{
            subscription.starting_date == null
              ? " - "
              : correctDate(subscription.starting_date)
          }}</a>
        </div>

        <div class="row manageServicesSubitem">
          <b class="col-sm-4 col-12">Date. Expiration</b>
          <a class="col-sm-8 col-12">
            {{
              subscription.exp_date == null
                ? " - "
                : correctDate(subscription.exp_date)
            }}
          </a>
        </div>
      </div>

      <div
        class="manageServicesItemPart2 col-xl-2 col-12 text-center mt-xl-2 mt-3"
      >
        <b>Etat</b>
        <div class="d-flex w-100 justify-content-center">
          <button
            v-if="subscription.waiting_for_payment >= 1"
            class="staticBtn bg-primary text-light fw-normal"
          >
            Paiement en attente
          </button>
          <button
            v-else-if="subscription.payment_date == null"
            class="staticBtn bg-dark text-light fw-normal"
          >
            Initié
          </button>
          <button
            v-else-if="
              subscription.exp_date === '' || subscription.exp_date === null
            "
            class="staticBtn bg-success text-white fw-normal"
          >
            Payé
          </button>
          <button
            v-else-if="
              subscription.domain_name != null &&
              moment(subscription.exp_date) > currentDate() &&
              subscription.is_valid_docs == 0
            "
            class="staticBtn manageServicesExpDate"
          >
            A confirmer
          </button>
          <button
            v-else-if="
              subscription.payment_date !== null &&
              moment(subscription.exp_date) > currentDate()
            "
            class="staticBtn bg-success"
          >
            Actif
          </button>
          <button
            v-else-if="
              subscription.payment_date !== null &&
              moment(subscription.exp_date) < currentAfterDate()
            "
            class="staticBtn bg-danger text-dark"
          >
            Suspendu
          </button>
          <button v-else class="staticBtn bg-warning fw-500 text-danger">
            Expiré
          </button>
        </div>
      </div>

      <div class="d-flex justify-content-center col-xl-4">
        <div class="w-auto text-center">
          <div
            class="manageServicesItemPart2 col-xl-2 col-12 text-center mt-xl-4 mt-0"
          >
            <button
              v-if="subscription.payment_date == null"
              class="manageServicesExtend btn-primary btn"
              :sid="subscription.id"
              :ids="subscription.offer_id"
              :itemId="subscription.id"
              :sindex="index"
              @click="pay"
            >
              Solder
            </button>
            <!--  -->
            <button
              v-if="subscription.payment_date == null"
              id="removeSubs"
              class="manageServicesExtend btn-danger btn"
              :sid="subscription.id"
              :ids="subscription.offer_id"
              :itemId="subscription.id"
              @click="removeSubscription()"
            >
              Supprimer
            </button>
            <!--  -->
            <button
              v-else-if="
                subscription.domain_name != null &&
                moment(subscription.exp_date) > currentDate() &&
                subscription.is_valid_docs == 0 &&
                subscription.registry_doc_path.length == 0
              "
              class="manageServicesExtend btn-warning btn text-dark fw-bolder"
              :sid="subscription.id"
              :ids="subscription.offer_id"
              @click="registerDomain(subscription.domain_name, subscription.id)"
              :sindex="index"
            >
              Confirmer
            </button>
            <!--  -->
            <button
              v-else-if="
                subscription.is_valid_docs == 1 &&
                subscription.waiting_for_payment == 0 &&
                moment(subscription.exp_date) > currentDate()
              "
              class="manageServicesExtend btn-secondary btn"
              :sid="subscription.id"
              :ids="subscription.offer_id"
              @click="renew"
              :sindex="index"
            >
              Prolonger
            </button>
            <!--  -->
            <button
              v-else-if="
                subscription.is_valid_docs == 1 &&
                subscription.waiting_for_payment == 0 &&
                moment(subscription.exp_date) > currentAfterDate()
              "
              class="manageServicesExtend btn-success btn"
              :sid="subscription.id"
              :ids="subscription.offer_id"
              @click="renew"
              :sindex="index"
            >
              Renouveler
            </button>
          </div>

          <div
            class="manageServicesItemPart3 col-xl-2 col-12 text-center mt-xl-3 mt-0"
          >
            <button
              class="manageServicesDetailsBtn"
              @click="subscriptionDetails(subscription, index)"
            >
              Détails
            </button>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row w-10 mt-2 manageServicesDetails">
        <hr class="mt-2" />

        <div class="manageServicesItemPart1 col-xl-6 col-12 float-start">
          <div class="row manageServicesSubitem">
            <b class="col-sm-4 col-12">Entreprise</b>
            <a class="col-sm-8 col-12">{{ subscription.enterprise_name }}</a>
          </div>

          <div class="row manageServicesSubitem">
            <b class="col-sm-4 col-12">Type d'offre</b>
            <a class="col-sm-8 col-12">{{
              subscription.subscription_price_period == "Y"
                ? "Annuelle"
                : "Mensuelle"
            }}</a>
          </div>

          <div class="row manageServicesSubitem">
            <b class="col-sm-4 col-12">Période(s)</b>
            <a class="col-sm-8 col-12">{{
              subscription.nbr_period_payed +
              " " +
              (subscription.subscription_price_period == "Y" ? "Ans" : "Mois")
            }}</a>
          </div>

          <div class="row manageServicesSubitem">
            <b class="col-sm-4 col-12">Achat</b>
            <a class="col-sm-8 col-12"
              >{{ correctPrice(subscription.subscription_price) }}
              <strong>FCFA HT</strong></a
            >
          </div>

          <div class="row manageServicesSubitem">
            <b class="col-sm-4 col-12">Réabonnement</b>
            <a class="col-sm-8 col-12"
              >{{ correctPrice(subscription.subscription_reab) }}
              <strong>FCFA HT</strong></a
            >
          </div>
        </div>
      </div>
      <!--  -->
    </div>
  </div>
</template>

<script>
const $ = require("jquery");
import axios from "axios";
import localforage from "localforage";
import functions from "/src/common/js/functions";
import Globals from "/src/common/js/Globals";
import waitLoader from "/src/common/js/waitLoader";
import popup from "/src/common/js/popup";
import moment from "moment";
//
import { mapStores } from "pinia";
import { useUsersStore } from "/src/common/stores/users.js";

//allServices component
export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  data() {
    return {
      pageName: "Mes services",
      subscriptions: [],
      userSubscriptions: [],
      filterIndex: 0,
      offerTypes: [],
      serverTime: "",
    };
  },

  mounted() {
    //set the routeName
    $("#routeName")[0].innerHTML = this.pageName;
    //
    waitLoader.show();
    //for update on vue mounted
    document.addEventListener("clientMainIsReady", () => {
      this.__update();
    });
    if (Globals.clientMainIsOk) {
      this.__update();
    }
  },

  methods: {
    __update() {
      waitLoader.show();
      //
      setTimeout(() => {
        this.update();
      }, 1);
    },

    forceUpdate() {
      waitLoader.show();
      setTimeout(() => {
        this.update();
      }, 100);
    },

    async update() {
      //get server time
      this.serverTime = this.getServerTimeInMs();
      //
      //get all services types in json
      try {
        const resp = await axios.get(
          Globals.website_url + "php/api.php?query=get-services-types"
        );
        this.offerTypes = resp.data;
      } catch (err) {
        // Handle Error Here
        waitLoader.hide();
        console.error(err);
        return;
      }
      //
      //get All user data in json format
      let id = this.usersStore.User.account.id;
      id = functions.correctQueryString(id);
      try {
        const resp = await axios.get(
          Globals.website_url + "php/api.php?query=get-user-offers-by-id/" + id
        );
        this.userSubscriptions = resp.data;
        //
        this.userSubscriptions.forEach((subs) => {
          subs.registry_doc_path = JSON.parse(subs.registry_doc_path);
          if (subs.domain_name != null) {
            subs["domain_extension"] = subs.domain_name.substring(
              subs.domain_name.lastIndexOf(".")
            );
          }
        });
        //
        this.filter();
      } catch (err) {
        // Handle Error Here
        waitLoader.hide();
        console.error(err);
        return;
      }
      //
      waitLoader.hide();
    },

    subscriptionDetails(subscription, index) {
      this.userSubscriptions.forEach((item, id) => {
        if (id != index && item.shown) {
          item.shown = false;
          $(".manageServicesDetailsBtn")[id].classList.remove("bg-arrow-up");
          $(".manageServicesDetails")[id].style.display = "none";
        }
        if (id == index) {
          if (!item.shown) {
            item.shown = true;
            $(".manageServicesDetails")[index].style.display = "block";
            $(".manageServicesDetailsBtn")[id].classList.add("bg-arrow-up");
          } else {
            item.shown = false;
            $(".manageServicesDetails")[index].style.display = "none";
            $(".manageServicesDetailsBtn")[id].classList.remove("bg-arrow-up");
          }
        }
      });

      /*if (subscription.shown) {
  
      }*/
    },

    correctPrice(price) {
      return functions.correctPrice(price);
    },

    moment(date) {
      return moment(date);
    },

    correctDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    getServerTimeInMs() {
      return (
        functions.syncHttpGet(
          Globals.website_url + "php/api.php?query=get-current-time-in-ms"
        ) - 0
      );
    },

    currentDate() {
      const current = new Date(this.serverTime);
      return current;
    },

    currentPreDate() {
      //14 days or 2 weeks before exp
      const current = new Date(this.serverTime + 1209600000);
      return current;
    },

    currentAfterDate() {
      //14 days or 2 weeks before exp
      const current = new Date(this.serverTime - 30 * 24 * 60 * 60 * 1000);
      return current;
    },

    registerDomain(domain_name, subs_id) {
      let dots = domain_name.split(".");
      let ext = dots[dots.length - 1];
      //
      switch (ext) {
        case "bf": {
          this.gotoRegisterDomainBF(domain_name, subs_id);
          break;
        }
        default: {
          this.gotoRegisterDomain(domain_name);
        }
      }
    },

    gotoRegisterDomainBF(domain_name, subs_id) {
      this.usersStore.User.activateDomain = domain_name;
      this.usersStore.User.activateDomainSubsId = subs_id;
      //save cache data
      localforage
        .setItem("ecocloud/User", JSON.stringify(this.usersStore.User))
        .then(() => {
          this.$router.push("/client/domain-contact-details-bf");
          // this.$router.push("/client/domainBfFormAlt");
          //
        })
        .catch(function () {
          // we got an error
          waitLoader.hide();
        });
    },

    gotoRegisterDomain(domain_name) {
      this.usersStore.User.activateDomain = domain_name;
      //save cache data
      localforage
        .setItem("ecocloud/User", JSON.stringify(this.usersStore.User))
        .then(() => {
          this.$router.push("/client/domain-contact-details");
          //
        })
        .catch(function () {
          // we got an error
          waitLoader.hide();
        });
    },

    async renew(e) {
      waitLoader.show();
      //
      let sindex = e.target.getAttribute("sindex");
      //
      let sid = e.target.getAttribute("sid");
      this.usersStore.User.account.curSubscriptionIds = sid;
      let oid = e.target.getAttribute("ids");
      this.usersStore.User.account.curServiceId = oid;
      this.usersStore.User.account.curOfferId =
        this.subscriptions[sindex].subscription_price_period == "Y" ? 1 : 0;
      this.usersStore.User.account.curOfferDomain =
        this.subscriptions[sindex].domain_name;
      this.usersStore.User.account.curOfferDomainPrice =
        this.subscriptions[sindex].subscription_reab;
      this.usersStore.User.account.hasDomain =
        this.userSubscriptions[sindex].has_domain;
      // this.usersStore.User.account.curOfferModalityId = this.offerMonth ? 0 : 1;
      //
      let route = "";
      if (this.subscriptions[sindex].standart == 0) {
        this.usersStore.User.account.payOperationType = 3;
        route = "/client/choose-renew-offer";
      } else if (this.subscriptions[sindex].standart == 1) {
        this.usersStore.User.account.payOperationType = 1;
        route = "/client/payment";
      }
      //save cache data
      try {
        await localforage.setItem(
          "ecocloud/User",
          JSON.stringify(this.usersStore.User)
        );
      } catch (err) {
        // This code runs if there were any errors.
      }
      waitLoader.hide();
      //
      this.$router.push(route);
    },

    async pay(e) {
      waitLoader.show();
      //
      let sindex = e.target.getAttribute("sindex");
      //
      let sid = e.target.getAttribute("sid");
      this.usersStore.User.account.curSubscriptionIds = sid;
      let oid = e.target.getAttribute("ids");
      this.usersStore.User.account.curServiceId = oid;
      this.usersStore.User.account.curOfferId =
        this.subscriptions[sindex].subscription_price_period == "Y" ? 1 : 0;
      this.usersStore.User.account.curOfferDomain =
        this.subscriptions[sindex].domain_name;
      this.usersStore.User.account.curOfferDomainPrice =
        this.subscriptions[sindex].subscription_price;
      // this.usersStore.User.account.curOfferModalityId = this.offerMonth ? 0 : 1;
      //
      //FB CONFIRM DOMAIN FOR PAY DOMAIN
      window.fbq("trackCustom", "ConfirmPaidDomain", {
        user_country: this.subscriptions[sindex].user_country,
        offer_type: this.subscriptions[sindex].subscription_type,
        offer_name: this.subscriptions[sindex].subscription_label,
        domain: this.subscriptions[sindex].domain_name,
        price: this.subscriptions[sindex].subscription_price,
        renew: this.subscriptions[sindex].subscription_reab,
      });
      //Google Analytics
      this.$gtag.event("ConfirmPaidDomain", {
        user_country: this.subscriptions[sindex].user_country,
        offer_type: this.subscriptions[sindex].subscription_type,
        offer_name: this.subscriptions[sindex].subscription_label,
        domain: this.subscriptions[sindex].domain_name,
        price: this.subscriptions[sindex].subscription_price,
        renew: this.subscriptions[sindex].subscription_reab,
      });
      //
      let route = "";
      if (this.subscriptions[sindex].standart == 0) {
        this.usersStore.User.account.payOperationType = 0;
        route = "/client/payment";
      } else if (this.subscriptions[sindex].standart == 1) {
        this.usersStore.User.account.payOperationType = 2;
        route = "/client/payment";
      }
      this.usersStore.User.account.hasDomain =
        this.userSubscriptions[sindex].has_domain;
      //
      //save cache data
      try {
        await localforage.setItem(
          "ecocloud/User",
          JSON.stringify(this.usersStore.User)
        );
      } catch (err) {
        // This code runs if there were any errors.
      }
      //
      waitLoader.hide();
      this.$router.push(route);
    },

    removeSubscription() {
      popup.action = "remove-subscription";
      popup.setTitle("Notification");
      popup.setMessage("Voulez-vous vraiment supprimer cet enregistrement?");
      popup.setButton("Oui", "Non");
      popup.show();

      $("#popupYes").click(() => {
        if (popup.action != "remove-subscription") return;

        let subsId = $("#removeSubs").attr("sid");
        //
        axios
          .post(
            Globals.website_url +
              "php/api.php?query=user-remove-subscription/" +
              subsId
          )
          .then((response) => {
            let h = response.data;
            //on error
            if (h != "1") {
              waitLoader.hide();
              //say error
              popup.action = "notification";
              popup.setTitle("Notification");
              popup.setMessage("Impossible de supprimer cet enregistrement.");
              popup.setButton("Ok");
              popup.show();
              //
              return;
            }
            //
            waitLoader.hide();
            //
            this.update();
            //say error
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage("Enregistrement supprimé avec succès!");
            popup.setButton("Ok");
            popup.show();
          })
          .catch(() => {
            waitLoader.hide();
            //say error
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage("Veuillez vérifier votre connexion à internet.");
            popup.setButton("Ok");
            popup.show();
          });
      });
    },

    filter() {
      this.filterIndex = document.querySelector(
        "#allServicesFilterSelect"
      ).selectedIndex; //selected option index
      //
      this.subscriptions = this.userSubscriptions.filter((item) => {
        switch (this.filterIndex) {
          case 0: {
            return true;
          }
          case 1: {
            if (this.moment(item.exp_date) >= this.currentDate()) return true;
            return false;
          }
          case 2: {
            if (item.payment_date == null && item.exp_date == null) return true;
            return false;
          }
          case 3: {
            if (this.moment(item.exp_date) < this.currentDate()) return true;
            return false;
          }
          case 4: {
            if (item.waiting_for_payment == 1) return true;
            return false;
          }
          default: {
            let filterValue = $("#allServicesFilterSelect option")[
              this.filterIndex
            ].innerText;
            if (
              item.subscription_type.toLowerCase() == filterValue.toLowerCase()
            )
              return true;
            return false;
          }
        }
      });
    },
  },
};
</script>

<style scoped>
/*--------------------------------------------
All Souscriptions
--------------------------------------------*/
#allServices {
  max-width: 1024px;
  width: 100%;
  margin: auto;
  padding: 0 8px;
  height: max-content;
}

#allServicesFilter {
  margin-top: 8px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
}

#allServicesFilter a {
  font-size: 23px;
  margin-right: 4px;
}

#allServicesFilter select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("/src/assets/images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 5px;
  background-color: white;
  padding: 4px 16px;
  padding-right: 32px;
  /**/
  border: solid 1px #ddd;
  border-radius: 4px;
  margin-right: 16px;
}

.manageServicesItem {
  width: 100%;
  padding: 16px 28px;
  background-color: #f2fbff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  height: max-content;
  color: #111a2b;
  margin-bottom: 24px;
  margin-left: 3px;
}

.manageServicesItem:not([empty]) {
  animation: anim3d ease 0.75s;
  transform-origin: top center;
}

@keyframes anim3d {
  0% {
    transform: perspective(75em) rotateX(30deg);
  }

  25% {
    transform: perspective(75em) rotateX(-30deg);
  }

  50% {
    transform: perspective(45em) rotateX(15deg);
  }

  75% {
    transform: perspective(45em) rotateX(-15deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

.manageServicesSubitem {
  text-align: left;
}

.manageServicesSubitem:nth-child(odd) {
  background-color: white;
}

.manageServicesItem a {
  color: black;
}

.manageServicesItem b {
}

.manageServicesItem button {
  margin-bottom: 8px;
  border-radius: 16px;
  padding: 8px 0;
  color: white;
  border: none;
  width: 112px;
}

.manageServicesExtend {
  display: block;
  font-size: 13px;
  border-radius: 16px !important;
  padding: 10px 0 !important;
}

.manageServicesExpDate {
  background-color: #f60 !important;
}

.manageServicesItemPart2 button {
  padding: 3px 12px;
  border-radius: 16px;
  font-weight: bold;
  border: none;
}

.staticBtn {
  cursor: default !important;
  font-size: 13px;
}

#manageServicesEmpty {
  border-radius: 0;
  padding: 8px 8px;
  width: 240px;
  font-weight: bold;
  background-color: #111a2b;
}

.manageServicesDetailsBtn {
  background-color: transparent !important;
  background-image: url("/src/assets/images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 4px;
  color: black !important;
  border: solid 3px #111a2b !important;
  padding: 5px 0 !important;
}

.bg-arrow-up {
  background-image: url("/src/assets/images/select-arrow-up.svg");
}

.manageServicesDetails {
  display: none;
  margin-left: 0px;
}

@media only screen and (max-width: 600px) {
  .manageServicesItem {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  #allServicesFilter a {
    display: none;
  }

  #allServicesFilter {
    display: block;
  }

  #allServicesFilterSelect {
    width: 100%;
    display: block;
    margin-bottom: 8px;
  }

  #subsUpdateBtn {
    margin-bottom: 24px;
    width: 100% !important;
    display: block;
  }
}
</style>
