<template>
  <main id="factures" class="text-center">
    <div id="facturesFilter" class="mt-4">
      <div id="factSearchBox" class="input-group mb-3">
        <i class="input-group-text bi bi-search"></i>
        <input @input="filter" type="search" class="form-control" placeholder="Recherche..." id="facturesSearchInput">
      </div>

      <button id="factUpdateBtn" type="button" class="form-control btn-success border-success w-auto"
        @click="forceUpdate()" enable="true">
        Actualiser <i class="bi bi-arrow-clockwise text-white"></i>
      </button>
    </div>


    <div v-if="domains.length == 0" class="facturesItem row w-100 d-flex text-center justify-content-center" empty>
      <h5>Vous n'avez aucun domaine à votre actif</h5>
    </div>


    <div class="facturesItem row p-2" v-for="(domain, index) in domains" :key="index"
      v-show="moment(domain.exp_date) > currentDate() || domain.is_valid_docs == 1">
      <div class="facturesItemPart1 col-xl-6 col-12">
        <div class="row facturesSubitem">
          <b class="col-sm-6 col-6">Nom de domaine</b>
          <a class="col-sm-6 col-6">{{ domain.domain_name }}</a>
        </div>

        <div class="row facturesSubitem">
          <b class="col-sm-6 col-6">Service</b>
          <a class="col-sm-6 col-6">{{ domain.subscription_type + " " + domain.subscription_label }}</a>
        </div>

        <div class="row facturesSubitem">
          <b class="col-sm-6 col-6">Date d'expiration</b>
          <a class="col-sm-6 col-6">{{ domain.exp_date }}</a>
        </div>

        <!-- <div class="row facturesSubitem">
          <b class="col-sm-6 col-6">Status domaine</b>
          <button v-if="moment(domain.exp_date) > currentDate()" class="staticBtn manageServicesExpDate">Actif</button>
          <butto v-else-if="moment(domain.exp_date) < currentAfterDate()" class="staticBtn bg-danger text-dark">Suspendu
          </butto>
          <button v-else class="staticBtn bg-warning fw-500 text-danger">Expiré</button>
        </div> -->
      </div>

      <div class="d-flex justify-content-center col-xl-6">
        <div class="w-auto text-center d-flex align-items-center"
          v-if="domain.domain_name != null && getDomainExtension(domain.domain_name) === 'bf'">
          <div class="facturesItemPart3 text-center">
            <button @click="showManageDnsForm(domain)" class="btn btn-sm btn-primary me-2 my-1" :ids="domain.id">
              Serveur DNS
            </button>
            <button @click="updateDomainContacts(domain.domain_name)" class="btn btn-sm btn-primary me-2 my-1">
              Contacts
            </button>
            <button @click="infos(domain.domain_name)" class="btn btn-sm btn-primary me-2 my-1">
              Infos
            </button>
          </div>
        </div>
      </div>

    </div>
  </main>


  <!-- EDIT DNS MODAL -->
  <div class="modal" id="dnsManageModalBack">
    <div class="modal-dialog" id="dnsManageModal" style="width:1024px;max-width: 100%;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Editer les serveurs DNS</h5>
          <button type="button" class="btn-close closeBtn" modalId="dnsManageModalBack"></button>
        </div>
        <form @submit.prevent="validateManageDNS()">
          <!--  -->
          <div class="modal-body">
            <div class="input-group mb-3">
              <a class="input-group-text">Domaine</a>
              <input type="text" class="form-control" id="selDomain" disabled>
            </div>

            <div class="row">
              <div class="col-md col-12 input-group mb-2">
                <a class="input-group-text">Serveur 1</a>
                <input type="text" class="form-control" id="serverName1">
              </div>

              <div class="col-md col-12 input-group mb-2">
                <a class="input-group-text">Ipv4</a>
                <input type="text" class="form-control" id="ipv4_1">
              </div>

              <div class="col-md col-12 input-group mb-2">
                <a class="input-group-text">Ipv6</a>
                <input type="text" class="form-control" id="ipv6_1">
              </div>
            </div>

            <div class="row">
              <div class="col-md col-12 input-group mb-2">
                <a class="input-group-text">Serveur 2</a>
                <input type="text" class="form-control" id="serverName2">
              </div>

              <div class="col-md col-12 input-group mb-2">
                <a class="input-group-text">Ipv4</a>
                <input type="text" class="form-control" id="ipv4_2">
              </div>

              <div class="col-md col-12 input-group mb-2">
                <a class="input-group-text">Ipv6</a>
                <input type="text" class="form-control" id="ipv6_2">
              </div>
            </div>

            <div class="row">
              <div class="col-md col-12 input-group mb-2">
                <a class="input-group-text">Serveur 3</a>
                <input type="text" class="form-control" id="serverName3">
              </div>

              <div class="col-md col-12 input-group mb-2">
                <a class="input-group-text">Ipv4</a>
                <input type="text" class="form-control" id="ipv4_3">
              </div>

              <div class="col-md col-12 input-group mb-2">
                <a class="input-group-text">Ipv6</a>
                <input type="text" class="form-control" id="ipv6_3">
              </div>
            </div>

            <div class="row">
              <div class="col-md col-12 input-group mb-2">
                <a class="input-group-text">Serveur 4</a>
                <input type="text" class="form-control" id="serverName4">
              </div>

              <div class="col-md col-12 input-group mb-2">
                <a class="input-group-text">Ipv4</a>
                <input type="text" class="form-control" id="ipv4_4">
              </div>

              <div class="col-md col-12 input-group mb-2">
                <a class="input-group-text">Ipv6</a>
                <input type="text" class="form-control" id="ipv6_4">
              </div>
            </div>

          </div>
          <!--  -->
          <div class="modal-footer">
            <button type="button" class="closeBtn btn btn-secondary w-auto"
              modalId="dnsManageModalBack">Annuler</button>
            <button type="submit" class="closeBtn btn btn-primary w-auto" modalId="dnsManageModalBack">Modifier les
              DNS</button>
          </div>
        </form>
      </div>
    </div>
  </div>


  <!-- EDIT DNS MODAL -->
  <div class="modal" id="infosModalBack">
    <div class="modal-dialog" id="infosModal" style="width:512px;max-width: 100%;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Domaine infos</h5>
          <button type="button" class="btn-close closeBtn" modalId="infosModalBack"></button>
        </div>
        <div>
          <!--  -->
          <div class="modal-body">
            <div class="row mb-3">
              <a>Domaine: </a>
              <a>{{ curDomainInfo.domain }}</a>
            </div>

            <div class="row mb-3">
              <a>Création: </a>
              <a>{{ curDomainInfo.created_at }}</a>
            </div>

            <div class="row mb-3">
              <a>Mise à jour: </a>
              <a>{{ curDomainInfo.updated_at }}</a>
            </div>

            <div class="row mb-3">
              <a>Expiration: </a>
              <a>{{ curDomainInfo.expire_at }}</a>
            </div>
          </div>
          <!--  -->
          <div class="modal-footer">
            <button type="button" class="closeBtn btn btn-secondary w-auto" modalId="infosModalBack">Ok</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>


<script>
const $ = require("jquery");
import axios from "axios";
import functions from "/src/common/js/functions";
import Globals from "/src/common/js/Globals";
import moment from "moment";
import waitLoader from "/src/common/js/waitLoader";
import popup from "/src/common/js/popup";
import localforage from "localforage";
//
import { mapStores } from 'pinia';
import { useUsersStore } from "/src/common/stores/users.js";
//

//UserData component
export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  data() {
    return {
      pageName: "Mes domaines",
      domains: Array(),
      offerTypes: Array(),
      filterIndex: 0,
      serverTime: "",
      curDomain: Array(),

      curDomainInfo: {},
    };
  },

  mounted() {
    //set the routeName
    $("#routeName")[0].innerHTML = this.pageName;
    //
    $(".closeBtn").click(function () {
      $("#" + this.getAttribute("modalId")).css("display", "none");
    });
    //
    waitLoader.hide();
    //for update on vue mounted
    document.addEventListener("clientMainIsReady", () => {
      this.__update();
    });
    if (Globals.clientMainIsOk) {
      this.__update();
    }
  },

  methods: {
    __update() {
      waitLoader.show();
      //
      setTimeout(() => {
        this.update();
      }, 1);
    },

    forceUpdate() {
      waitLoader.show();
      setTimeout(() => {
        this.update();
      }, 100);
    },

    async update() {
      //get server time
      this.serverTime = this.getServerTimeInMs();

      //get All user data in json format
      let id = this.usersStore.User.account.id;
      id = functions.correctQueryString(id);
      try {
        const resp = await axios.get(Globals.website_url + "php/api.php?query=get-user-offers-by-id/" + id);
        this.domains = resp.data;
      } catch (err) {
        // Handle Error Here
        waitLoader.hide();
        console.error(err);
        return;
      }
      //
      waitLoader.hide();
    },

    getDomainExtension(domainName) {
      return domainName = domainName.substring(domainName.lastIndexOf(".") + 1, domainName.length);
    },

    correctPrice(price) {
      return functions.correctPrice(price);
    },

    moment(date) {
      return moment(date);
    },

    correctDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    currentDate() {
      const current = new Date(this.serverTime);
      return current;
    },

    currentPreDate() {
      //14 days or 2 weeks before exp
      const current = new Date(this.serverTime + 1209600000);
      return current;
    },

    currentAfterDate() {
      //14 days or 2 weeks before exp
      const current = new Date(this.serverTime - 30 * 24 * 60 * 60 * 1000);
      return current;
    },

    getServerTimeInMs() {
      return functions.syncHttpGet(Globals.website_url + "php/api.php?query=get-current-time-in-ms") - 0;
    },

    filter(e) {
      var str = e.target.value;
      //
      let objs = document.querySelectorAll(".facturesItem");
      objs.forEach(function (item) {
        $(item).hide();
        //
        if (item.textContent.toLowerCase().indexOf(str.toLowerCase()) >= 0) {
          $(item).show();
        }
      });
    },




    updateDomainContacts(domain_name) {
      let dots = domain_name.split(".");
      let ext = dots[dots.length - 1];
      //
      switch (ext) {
        case "bf": {
          this.gotoUpdateDomainContactsBF(domain_name);
          break;
        }
        default: {
          this.gotoUpdateDomainContacts(domain_name);
        }
      }
    },

    gotoUpdateDomainContactsBF(domain_name) {
      this.usersStore.User.updateDomain = domain_name;
      //save cache data
      localforage.setItem('ecocloud/User', JSON.stringify(this.usersStore.User))
        .then(() => {
          this.$router.push("/client/update-domain-contacts");
          //
        }).catch(function () {
          // we got an error
          waitLoader.hide();
        });
    },

    gotoUpdateDomainContacts(domain_name) {
      this.usersStore.User.updateDomain = domain_name;
      //save cache data
      localforage.setItem('ecocloud/User', JSON.stringify(this.usersStore.User))
        .then(() => {
          //
        }).catch(function () {
          // we got an error
          waitLoader.hide();
        });
    },





    showManageDnsForm(domain) {
      $("#selDomain").val(domain.domain_name);
      this.curDomain = domain;

      waitLoader.show();

      setTimeout(async function () {
        try {
          const resp = await axios.get(Globals.website_url + "php/api.php?query=get-domain-infos/" + domain.domain_name);
          let h = resp.data;
          //on error or no editable domain dns
          if (h == "") {
            waitLoader.hide();
            return;
          }

          h = JSON.parse(h);

          // console.log(h);

          if (h.success) {
            let data = h.data;

            let cnt = 1;
            data.nameservers.forEach((server) => {
              $('#serverName' + cnt).val(server.name);
              //
              if (server.ipAddresses) {
                let cnt2 = 1;
                server.ipAddresses.forEach((ip) => {
                  if (cnt2 == 1) {
                    $('#ipv4_' + cnt).val(ip);
                  }
                  else {
                    $('#ipv6_' + cnt).val(ip);
                  }
                  cnt2++;
                });
              }
              //
              cnt++;
            });
            //
            $('#dnsManageModalBack').css("display", "block");
          }
          //
          waitLoader.hide();
        } catch (err) {
          // Handle Error Here
          console.error(err);
          waitLoader.hide();
        }
      }, 100);
    },

    validateManageDNS() {
      waitLoader.show();
      //
      let data = {
        Domain: this.curDomain.domain_name,
        Years: $("#rDomainYears").val(),
      };
      //
      data["rServer_1"] = $("#serverName1").val();
      data["rIPv4_1"] = $("#ipv4_1").val();
      data["rIPv6_1"] = $("#ipv6_1").val();
      data["rServer_2"] = $("#serverName2").val();
      data["rIPv4_2"] = $("#ipv4_2").val();
      data["rIPv6_2"] = $("#iPv6_2").val();
      data["rServer_3"] = $("#serverName3").val();
      data["rIPv4_3"] = $("#ipv4_3").val();
      data["rIPv6_3"] = $("#iPv6_3").val();
      data["rServer_4"] = $("#serverName4").val();
      data["rIPv4_4"] = $("#ipv4_4").val();
      data["rIPv6_4"] = $("#iPv6_4").val();
      //
      //save data into db for update after complete all domain register contacts steps
      let fd = new FormData();
      fd.append("query", "set-domain-dns/" + functions.correctQueryString(JSON.stringify(data)));
      //
      axios.post(Globals.website_url + "php/api.php", fd).then((response) => {
        let h = response.data;

        //on error
        if (h != 1) {
          waitLoader.hide();
          //say error
          popup.action = "Notification";
          popup.setTitle("Notification");
          popup.setMessage(
            "Erreur d'enregistrement des serveurs de noms!<br>Veuillez vérifier vos informations puis reéssayer."
          );
          popup.setButton("Ok");
          popup.show();
          return;
        }
        //
        waitLoader.hide();
        //
        popup.action = "Notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Les serveurs de noms ont été changés avec succès !"
        );
        popup.setButton("Ok");
        popup.show();
        //
      }).catch((error) => {
        //
        console.log(error);
        waitLoader.hide();
        //say error
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage("Problème de connexion!<br>Veuillez reéssayer.");
        popup.setButton("Ok");
        popup.show();
      });
    },



    infos(domain) {
      this.curDomain = domain;
      this.curDomainInfo.domain = domain;

      waitLoader.show();

      setTimeout(async () => {
        try {
          const resp = await axios.get(Globals.website_url + "php/api.php?query=get-domain-infos/" + domain);
          let h = resp.data;
          //on error or no editable domain dns
          if (h == "") {
            waitLoader.hide();
            return;
          }

          h = JSON.parse(h);

          // console.log(h);

          if (h.success) {
            let data = h.data;
            this.curDomainInfo.created_at = moment(data.created_at).format("DD/MM/YYYY HH:mm:ss");
            this.curDomainInfo.updated_at = moment(data.updated_at).format("DD/MM/YYYY HH:mm:ss");
            this.curDomainInfo.expire_at = moment(data.expire_at).format("DD/MM/YYYY HH:mm:ss");
            //
            $('#infosModalBack').css("display", "block");
          }
          //
          waitLoader.hide();
        } catch (err) {
          // Handle Error Here
          console.error(err);
          waitLoader.hide();
        }
      }, 100);
    },

  }
};
</script>

<style scoped>
#factures {
  color: black;
  width: 100%;
  float: left;
  text-align: center;
}

#facturesFilter {
  width: calc(100% - 48px);
  max-width: 100%;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
}

#factSearchBox {
  margin-left: 3%;
  max-width: 706px;
  width: 50% !important;
  margin-right: 16px;
}

#factUpdateBtn {
  height: 38px;
}





#facturesTitle {
  margin-bottom: 32px;
}

#facturesFilter {
  margin-top: 8px;
  margin-bottom: 24px;
}

#facturesFilter a {
  font-size: 23px;
  margin-right: 4px;
}

#facturesFilter select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("/src/assets/images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 5px;
  background-color: white;
  padding: 4px 16px;
  padding-right: 32px;
  /**/
  border: solid 1px #ddd;
  border-radius: 4px;
}




.facturesItem {
  margin: auto;
  max-width: 840px;
  width: 100%;
  padding: 16px 28px;
  background-color: #f2fbff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  height: max-content;
  color: #111a2b;
  margin-bottom: 24px;
  text-align: left;
}

.facturesItem:not([empty]) {
  transform-origin: top center;
  animation: anim3d ease 0.75s;
}

@keyframes anim3d {
  0% {
    transform: perspective(75em) rotateX(30deg);
  }

  25% {
    transform: perspective(75em) rotateX(-30deg);
  }

  50% {
    transform: perspective(45em) rotateX(15deg);
  }

  75% {
    transform: perspective(45em) rotateX(-15deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}





.facturesSubitem:nth-child(odd) {
  background-color: white;
}

.facturesItem a {
  color: black;
}

.facturesItem b {}

.facturesExpDate {
  background-color: green !important;
  cursor: default !important;
}

.facturesExpDateOver {
  background-color: #900 !important;
  cursor: default !important;
}

.facturesEmpty {
  width: 90%;
  max-width: 216px;
}

.facturesDetailsBtn {
  background-color: transparent !important;
  background-image: url("/src/assets/images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 4px;
  color: black !important;
  border: solid 3px #111a2b !important;
  padding: 5px 0 !important;
}

.bg-arrow-up {
  background-image: url("/src/assets/images/select-arrow-up.svg");
}

.facturesDetails {
  display: none;
  margin-left: 0px;
}

.facturesSubitem button {
  margin-bottom: 8px;
  border-radius: 16px;
  padding: 8px 0;
  color: white;
  border: none;
  width: 112px;
}

.staticBtn {
  cursor: default !important;
  font-size: 13px;
}

.facturesItemPart3 .break {
  display: none;
}

.manageServicesExpDate {
  background-color: #f60 !important;
}




.modal {
  background-color: rgba(0, 0, 0, 0.35);
}

.modal-dialog {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translateX(-50%) translateY(-50%);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 4px;
  z-index: 1000 !important;
}






@media only screen and (max-width:512px) {

  .facturesItemPart1 {}
}


@media only screen and (max-width:388px) {

  .facturesSubitem b,
  a {
    text-align: center;
    width: 100%;
  }

  .facturesSubitem {
    display: flex;
    justify-content: center !important;
  }
}


@media only screen and (max-width:512px) {
  .facturesItem {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  #facturesFilter a {
    display: none;
  }

  #facturesFilter {
    display: block;
    width: 100%;
    padding: 0;
  }

  #factSearchBox {
    margin-left: 0;
    margin-right: 0;
    width: 100% !important;
    margin-bottom: 8px;
  }

  #factUpdateBtn {
    margin-bottom: 24px;
    width: 100% !important;
    display: block;
  }
}
</style>