<template>
  <section id="leftMenuBack" @click="hideLeftMenu()"></section>
  <MyLeftMenu id="leftMenu"></MyLeftMenu>
  <section id="client">
    <MyHeader id="header"></MyHeader>
    <router-view></router-view>
  </section>
  <!--  -->
  <!-- MODAL FOR USER COMPLETE ACCOUNT DATA TO ACTIVATE ALL FEATURES -->
  <!--  -->
  <div class="modal" id="finalStepModalBack">
    <div class="modal-dialog modal-lg" id="finalStepModal">
      <form class="modal-content" @submit.prevent="validateEnterprise()">
        <div class="modal-header">
          <h5 class="modal-title w-100">Etape finale - Création du compte</h5>
        </div>

        <div class="modal-body row">
          <div class="col-sm col-12">
            <h5 class="w-100">Informations personnelles</h5>

            <div class="form-floating mb-3">
              <input type="text" id="userDataFname" spellcheck="false" class="form-control" placeholder="Krepin Jhon"
                :value="userData.fname" v-on:input="resetInput" required />
              <label for="userDataFname">Nom complet</label>
            </div>

            <div class="form-floating mb-3">
              <input type="email" id="userDataEmail" class="form-control" placeholder="krepin@jhon.com"
                :value="userData.email" readonly required />
              <label for="userDataEmail">Email</label>
            </div>

            <div class="form-floating mb-3 text-start">
              <a style="font-size: 14px" class="text-dark">Telephone</a>
              <br />
              <input type="tel" id="userDataTel" class="form-control" @input="resetInput" required />
            </div>
            <div class="form-floating mb-3">
              <CountryList id="userDataEditCountry"></CountryList>
              <label for="userDataEditCountry">Pays</label>
            </div>

            <div class="form-floating mb-3">
              <input type="text" id="userDataCity" class="form-control" placeholder="Ouagadougou" :value="userData.city"
                v-on:input="resetInput" required />
              <label for="userDataCity">Ville</label>
            </div>

            <div class="form-floating">
              <input type="text" id="userDataAddress" class="form-control" placeholder="Ouaga 2000"
                :value="userData.home_address" v-on:input="resetInput" required />
              <label for="userDataAddress">Adresse</label>
            </div>
            <div class="d-block mb-3 text-secondary text-start">
              <i class="bi bi-info-circle-fill me-1"></i>
              <a>Exemple: ouaga 2000 azimmo</a>
            </div>
          </div>

          <div class="col-sm col-12">
            <h5 class="w-100">Détails de facturation</h5>

            <div class="form-floating">
              <input type="text" id="userEnterpriseDataEditName" class="form-control" spellcheck="false"
                placeholder="Akaeyes Team" value="" @input="resetInput" />
              <label for="userEnterpriseDataEditName">Nom</label>
            </div>
            <div class="d-block mb-3 text-secondary">
              <i class="bi bi-info-circle-fill me-1"></i>
              <a>C'est le nom qui apparaitra sur vos factures.</a>
            </div>

            <div class="mb-3 text-start">
              <label style="font-size: 14px" for="userEnterpriseDataEditTel1" class="w-100">téléphone 1</label>
              <input type="tel" id="userEnterpriseDataEditTel1" class="form-control" @input="resetInput" required />
            </div>

            <div class="mb-3 text-start">
              <label style="font-size: 14px" for="userEnterpriseDataEditTel2" class="w-100">téléphone 2
                (facultatif)</label>
              <input type="tel" id="userEnterpriseDataEditTel2" class="form-control" @input="resetInput" non-required />
            </div>

            <div class="form-floating mb-3">
              <CountryList id="userEnterpriseDataEditCountry"></CountryList>
              <label for="userEnterpriseDataEditCountry">Pays</label>
            </div>

            <div class="form-floating mb-3">
              <input type="text" id="userEnterpriseDataEditCity" class="form-control" placeholder="Ouagadougou" value=""
                @input="resetInput" required />
              <label for="userEnterpriseDataEditCity">Ville</label>
            </div>

            <div class="form-floating">
              <input type="text" id="userEnterpriseDataEditAddress" class="form-control" placeholder="Ouaga 2000 Azimmo"
                value="" @input="resetInput" required />
              <label for="userEnterpriseDataEditAddress">Adresse</label>
            </div>
            <div class="d-block mb-3 text-secondary text-start">
              <i class="bi bi-info-circle-fill me-1"></i>
              <a>Exemple: ouaga 2000 azimmo</a>
            </div>
          </div>
        </div>

        <div class="modal-footer justify-content-center">
          <button type="submit" class="btn btn-primary">
            Enregistrer et terminer
          </button>
        </div>
      </form>
    </div>

    <PhoneNumberConfirm ref="phoneNumConf"></PhoneNumberConfirm>
  </div>
</template>

<script>
import ClientHeader from "./ClientHeader.vue";
import ClientLeftMenu from "./ClientLeftMenu.vue";
import Globals from "/src/common/js/Globals";
import localforage from "localforage";
import functions from "/src/common/js/functions";
import popup from "/src/common/js/popup";
import axios from "axios";
const $ = require("jquery");
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/js/utils"; // Import du script utils
import waitLoader from "/src/common/js/waitLoader";
import { Buffer } from "buffer/";
//
import countryList from "/src/components/countryList.vue";
import phoneNumberConfirm from "/src/components/phoneNumberConfirm.vue";
//
import { mapStores } from "pinia";
import { useUsersStore } from "/src/common/stores/users.js";

export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  components: {
    MyLeftMenu: ClientLeftMenu,
    MyHeader: ClientHeader,
    CountryList: countryList,
    PhoneNumberConfirm: phoneNumberConfirm,
  },

  data() {
    return {
      userData: {},
      //
      cypherCode: "39067A6F8088F81E9C2BB5D46A8C0F60",
      loginAsUser: {
        adminId: null,
        userId: null,
        code: null,
      },
      intTel: null,
      intTel1: null,
      intTel2: null,
    };
  },

  mounted() {
    Globals.clientMain = this;
    Globals.ClientleftMenu.manageLeftMenu();
    //
    this.initClientData();
    //
    this.intTel = intlTelInput($("#userDataTel")[0], {
      // any initialisation options go here
      initialCountry: "bf",
      preferredCountries: ["bf", "ci"],
    });
    //
    this.intTel1 = intlTelInput($("#userEnterpriseDataEditTel1")[0], {
      // any initialisation options go here
      initialCountry: "bf",
      preferredCountries: ["bf", "ci"],
    });
    this.intTel2 = intlTelInput($("#userEnterpriseDataEditTel2")[0], {
      // any initialisation options go here
      initialCountry: "bf",
      preferredCountries: ["bf", "ci"],
    });
    //
  },

  methods: {
    hideLeftMenu() {
      Globals.ClientleftMenu.closeLeftMenu();
    },

    async initClientData() {
      //check if a admin try to login as current user
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (
        urlParams.get("uide") != null &&
        urlParams.get("uiv") != null &&
        urlParams.get("con") != null &&
        urlParams.get("civ") != null
      ) {
        const hexaToBuffer = function (base64Data) {
          return Buffer.from(base64Data, "hex").buffer;
        };
        try {
          let uide = hexaToBuffer(urlParams.get("uide"));
          let uiv = hexaToBuffer(urlParams.get("uiv"));
          let con = hexaToBuffer(urlParams.get("con"));
          let civ = hexaToBuffer(urlParams.get("civ"));
          //
          this.loginAsUser.userId = await functions.decryptText(
            uide,
            this.cypherCode,
            uiv
          );
          this.loginAsUser.code = await functions.decryptText(
            con,
            this.cypherCode,
            civ
          );
        } catch (e) {
          await localforage.setItem("ecocloud/User", JSON.stringify({}));
          this.$router.replace("/login");
          return;
        }
        //
        let fd = new FormData();
        fd.append("query", "connect-admin-as-user/" + this.loginAsUser.userId + "/" + this.loginAsUser.code);
        let h = await axios.post(Globals.website_url + "php/api.php", fd);
        h = h.data;

        if (h <= 0 || h == {}) {
          h = 0;
        } else {
          let userData = { account: {}, data: {} };
          userData.account.id = h.id;
          userData.account.email = h.email;
          userData.account.password = h.passwd;
          userData.account.atLogin = true;
          userData.data = h;
          //
          await localforage.setItem("ecocloud/User", JSON.stringify(userData));
        }
      }
      //
      this.getAndCheckConnectedUserData();
    },

    async getAndCheckConnectedUserData() {
      try {
        let result = await localforage.getItem("ecocloud/User");
        let jsData = result;
        if (jsData == "" || jsData == "{}" || jsData == null) {
          this.$router.replace("/login");
          return;
        }
        jsData = JSON.parse(jsData);
        this.usersStore.User = jsData;
        //
        //check if user email is confirmed
        if (
          this.usersStore.User.data.creating_step <= 0 && this.usersStore.User.data.email_code != null && this.usersStore.User.data.email_code != ""
        ) {
          let amail = functions.correctQueryString(this.usersStore.User.account.email);
          popup.hide();
          location = "?amail=" + amail + "#/confirm-email";
          return;
        }
        //
        let email = jsData.account.email;
        email = functions.correctQueryString(email);
        let password = jsData.account.password;
        password = functions.correctQueryString(password + "");
        //
        let h = {};
        let fd = new FormData();
        fd.append("query", "check-if-user-is-connected/" + email + "/" + password);
        h = await axios.post(Globals.website_url + "php/api.php", fd);
        h = h.data;
        //
        if (h.length == 0 || h == {} || h === null) {
          this.$router.replace("/login");
          return;
        }
        //
        //
        //Load user data
        this.usersStore.User.account.id = h.id;
        this.usersStore.User.data = h;
        this.usersStore.User.data.passwd = h.passwd;
        //
        this.userData = h;
        this.intTel.setNumber(this.userData.tel);
        //
        //refresh local user data
        document.dispatchEvent(new CustomEvent('clientMainIsReady'));
        Globals.clientMainIsOk = true;
        //save cache data
        await localforage.setItem("ecocloud/User", JSON.stringify(this.usersStore.User));
        if (
          this.usersStore.User.data.creating_step != 1 && $("#finalStepModalBack")[0].style.display != "block"
        ) {
          //notify account for first step
          popup.action = "final-step";
          popup.setTitle("Eco Cloud");
          popup.setMessage(
            "Bienvenue sur votre Espace Client!<br><br>Vous pouvez desormais:<br><ul><li>Gérer vos Abonnements</li><li>Payer vos Noms de Domaines et Hébergements</li><li>Recevoir vos Factures et Alertes</li><li>Bénéficier d'une assistance vidéo et tout ça en ligne!</li></ul>"
          );
          popup.setButton("Ok");
          popup.show();

          $("#popupYes").click(() => {
            this.openFinalStepModal();
          });
        }
      } catch (e) {
        console.log(e);
        //
        this.$router.replace("/login");
      }
    },

    openFinalStepModal() {
      $("#finalStepModalBack").css("display", "block");
    },

    closeFinalStepModal() {
      $("#finalStepModalBack").css("display", "none");
    },

    resetInput: function (e) {
      let color = "#FFF";
      e.target.style.backgroundColor = color;
    },

    async validatePerso() {
      let fname = $("#userDataFname")[0].value;
      let tel = this.intTel.getNumber();
      let city = $("#userDataCity")[0].value;
      let address = $("#userDataAddress")[0].value;
      //
      //
      let error = false;
      let color = "#F66";
      //tel errors
      if (tel.length >= 1 && tel.length < 8) {
        document.getElementById("userDataTel").style.backgroundColor = color;
        error = true;
      }
      //name errors
      if (fname.length < 2) {
        document.getElementById("userDataFname").style.backgroundColor = color;
        error = true;
      }
      //city errors
      if (city.length >= 1 && city.length < 3) {
        document.getElementById("userDataCity").style.backgroundColor = color;
        error = true;
      }
      //address errors
      if (address.length >= 1 && address.length < 3) {
        document.getElementById("userDataAddress").style.backgroundColor = color;
        error = true;
      }
      //
      if (error) {
        return;
      }//
      Globals.finalStep = true;
      Globals.phoneNumberConfirm.phoneNumberConfirmSendCode(tel, this.usersStore.User.data.email);
    },

    validateEnterprise() {
      let name = $("#userEnterpriseDataEditName")[0].value;
      let tel1 = this.intTel1.getNumber();
      let tel2 = this.intTel2.getNumber();
      let country = $("#userEnterpriseDataEditCountry")[0].value;
      let city = $("#userEnterpriseDataEditCity")[0].value;
      let address = $("#userEnterpriseDataEditAddress")[0].value;
      //
      address = functions.correctQueryString(address);
      let error = false;
      let color = "#F66";
      //name errors
      if (name.length < 3) {
        document.getElementById(
          "userEnterpriseDataEditName"
        ).style.backgroundColor = color;
        error = true;
      }
      //city errors
      if (city.length < 3) {
        document.getElementById(
          "userEnterpriseDataEditCity"
        ).style.backgroundColor = color;
        error = true;
      }
      //address errors
      if (address.length < 3) {
        document.getElementById(
          "userEnterpriseDataEditAddress"
        ).style.backgroundColor = color;
        error = true;
      }
      //
      if (error) {
        return;
      }
      //
      //
      //for this popup action
      popup.action = "";
      let id = functions.correctQueryString(this.usersStore.User.account.id);
      //
      waitLoader.show();
      //to save new data
      axios
        .post(
          Globals.website_url +
          "php/api.php?query=set-user-enterprise-by-id/" +
          id +
          "/" +
          name +
          "/" +
          tel1 +
          "/" +
          tel2 +
          "/" +
          country +
          "/" +
          city +
          "/" +
          address
        )
        .then((response) => {
          let h = response.data;
          //on error
          if (h != "1") {
            waitLoader.hide();
            //say error
            popup.action = "Notification";
            popup.setTitle("Notification");
            popup.setMessage("Erreur d'enregistrement des données!<br>Veuillez reéssayer.");
            popup.setButton("Ok");
            popup.show();
            return;
          }
          //on success
          setTimeout(() => {
            Globals.ClientleftMenu.hasEnterprise = true;
          }, 1000);
          //
          //
          this.validatePerso();
          //
        })
        .catch((error) => {
          console.log(error);
          //
          waitLoader.hide();
        });
    },
  },
};
</script>

<style>
#client {
  position: absolute;
  background: url("/src/assets/images/app-bg.jpg") no-repeat center center fixed;
  background-size: cover;
  /**/
  left: 260px;
  right: 0;
  height: 100vh;
  overflow: hidden;
  transition: left 0.25s ease;
  display: flex;
  flex-direction: column;
}

main {
  overflow: auto;
  flex: 1;
}

table * {
  font-size: 14px;
}

#finalStepModalBack {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
